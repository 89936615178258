<template>
  <div class="forms mt-4" v-loading="loaders.form">
    <validation-observer v-slot="{handleSubmit}" ref="formValidator">
      <form role="form" type="multipart" @submit.prevent="handleSubmit(onSubmit)">
        <card type="default" header-classes="bg-transparent">

          <div class="row">
            <div class="col-lg-6 col-md-6">
              <base-input
                :rules="{required: true}"
                name="Title"
                label="Title*"
                v-model="request.trophyCard.title"
                placeholder="">
              </base-input>
            </div>
            <div class="col-lg-6 col-md-6">
              <label class="form-control-label">Select User Type*</label>
              <el-select
                class="w-100"
                multiple
                v-model="request.trophyCard.user_tier"
                placeholder="Select User Type"
              >
                <el-option
                  v-for="userType in userTiers"
                  class="select-danger"
                  :value="userType.value"
                  :label="userType.label"
                  :key="userType.value">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 col-md-6 d-flex justify-content-end align-items-center">
              <div class="img-upload w-100">
                <label class="form-control-label" for="profileImage">Upload Front Image*</label>
                <input type="file" id="profileImage" ref="profileImage" accept="image/png, image/gif, image/jpeg"
                       class="form-control file-upload" @change="onFrontImageChange">
                <span class="font-size-12px">Recommended image size 337*744 px. Must be less than 500kb.</span>
              </div>
              <el-image
                v-loading="loaders.image"
                class="img-size pl-2"
                :class="{'d-none':!frontImageExist}"
                :src="request.trophyCard.oldFrontImage"
                @load="handleLoad"
                @error="handleLoad"
              />
            </div>
            <div class="col-lg-6 col-md-6 d-flex justify-content-end align-items-center">
              <div class="img-upload w-100">
                <label class="form-control-label" for="profileImage">Upload Back Image</label>
                <input type="file" id="profileImage" ref="profileImage" accept="image/png, image/gif, image/jpeg"
                       class="form-control file-upload" @change="onBackImageChange">
                <span class="font-size-12px">Recommended image size 337*744 px. Must be less than 500kb.</span>
              </div>
              <el-image
                v-loading="loaders.image"
                class="img-size pl-2"
                :class="{'d-none':!backImageExist}"
                :src="request.trophyCard.oldBackImage"
                @load="handleLoad"
                @error="handleLoad"
              />
              <img
                v-if="backImageExist"
                src="/img/icons/buttons/remove-no-bg.svg"
                class="close-image ml-1 mt-2"
                alt="cross"
                @click="removeBackImage"
              >
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-lg-6 col-md-6">
              <label class="form-control-label">Based On*</label>
              <el-select
                class="w-100"
                v-model="request.trophyCard.rule_type"
                placeholder="Based On"
                @change="updateConditionsOnChangeRuleType"
              >
                <el-option
                  v-for="ruleType in ruleTypes"
                  class="select-danger"
                  :value="ruleType.value"
                  :label="ruleType.label"
                  :key="ruleType.value">
                </el-option>
              </el-select>
            </div>

            <div class="col-lg-6 col-md-6" v-if="!['surgery_date','specific_date'].includes(request.trophyCard.rule_type)">
              <label class="form-control-label" v-if="request.trophyCard.rule_type == 'food_log'">Food Logged For (Days)*</label>
              <label class="form-control-label" v-else>App Used For (Days)*</label>
              <div class="row">
                <div class="col-4 pr-0">
                  <input-number
                    class="days-input w-100"
                    v-model="request.trophyCard.days"
                    controls-position="right"
                    :min="1"/>
                </div>
                <div class="col-8">
                  <el-select
                    class="w-100"
                    v-model="request.trophyCard.days_condition"
                    placeholder=""
                  >
                    <el-option
                      v-for="daysCondition in daysConditions"
                      class="select-danger"
                      :value="daysCondition.value"
                      :label="daysCondition.label"
                      :key="daysCondition.value">
                    </el-option>
                  </el-select>
                </div>
              </div>

            </div>
            <div class="col-lg-6 col-md-6" v-else-if="request.trophyCard.rule_type == 'specific_date'">
              <label class="form-control-label" >Date*</label>
              <validation-provider :rules="{required: request.trophyCard.rule_type == 'specific_date'}" name="date" v-slot="{errors, valid, invalid,validated,validate}">
              <custom-date-picker
                    :inputData.sync="request.trophyCard.date"
                    v-model="request.trophyCard.date"
                    :minDate= "new Date()"
                    :show-placeholder="''"
                    :show-image-icon="false"
                    :class="{'is-invalid':validated && invalid}"
                    >
              </custom-date-picker>
              <div v-if="errors[0]" class="invalid-feedback" style="display: block;">
                    {{ errors[0] }}
              </div>
              </validation-provider>
            </div>

            <div class="col-lg-6 col-md-6" v-else-if="request.trophyCard.rule_type == 'surgery_date'">
              <label class="form-control-label">Surgery Date*</label>
              <div class="row">
                <div class="col-3">
                  <el-select
                    class="w-100"
                    v-model="request.trophyCard.days_condition"
                    placeholder=""
                  >
                    <el-option
                      v-for="surgeryDaysCondition in surgeryDaysConditions"
                      class="select-danger"
                      :value="surgeryDaysCondition.value"
                      :label="surgeryDaysCondition.label"
                      :key="surgeryDaysCondition.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="col-2 p-0">
                  <input-number
                    class="days-input w-100"
                    v-model="request.trophyCard.surgery_days"
                    controls-position="right"
                    :min="1"/>
                </div>
                <div class="col-7">
                  <el-select
                    class="w-100"
                    v-model="request.trophyCard.surgery_day_type"
                    placeholder=""
                  >
                    <el-option
                      v-for="surgeryDayType in surgeryDayTypes"
                      class="select-danger"
                      :value="surgeryDayType.value"
                      :label="surgeryDayType.label"
                      :key="surgeryDayType.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-lg-6 col-md-6">
              <base-input
                type="url"
                :rules="{required: true}"
                v-model="request.trophyCard.link"
                name="url"
                label="Link*"
                placeholder="">
              </base-input>
            </div>
          </div>

        </card>
        <card>
          <div class="row">
            <div class="col">
              <base-input
                :rules="{required: true}"
                name="Notification"
                label="Notification For Shop & Reward*"
                v-model="request.trophyCard.notification_content"
                placeholder="">
              </base-input>
            </div>
          </div>
        </card>
      </form>
      <base-button
        @click="onSubmit()"
        class="btn-primary-action mb-4 br-4 pr-5 pl-5"
        native-type="submit"
        type="submit">
        {{ submitButtonText }}
      </base-button>
    </validation-observer>
  </div>
</template>

<script>
import {InputNumber} from "element-ui"
import { extend } from "vee-validate"
import { required } from "vee-validate/dist/rules"
import CustomDatePicker from "@/views/Components/CustomDatePicker";

extend("required", {
    ...required, message: (field) => {
      let name = field.substr(0, 1).toUpperCase() + field.substr(1);
      return `This ${name} is required.`
    }
  }
)

export default {
  name: "TrophyCardForm",
  props: {
    submitButtonText: {
      type: String,
      default: 'Save',
      description: 'Submit Button Text'
    },
    cardTrophyDataToEdit: {
      type: Object,
      default: () => {
        return {
          title: '',
          user_tier: [],
          rule_type: 'food_log',
          date: '',
          days: 1,
          days_condition: 'in_row',
          surgery_day_type: 'day',
          surgery_days: 1,
          oldFrontImage: '',
          ad_front_image: '',
          oldBackImage: '',
          ad_back_image: '',
          link: '',
          notification_content: '',
        };
      },
      description: 'Card Trophy type object'
    }
  },
  components: {
    InputNumber,
    CustomDatePicker
  },
  data() {
    return {
      loaders: {
        form: false,
        image: false
      },
      ruleTypes: [
        {label: 'Food Log', value: 'food_log'},
        {label: 'App Usage', value: 'app_usage'},
        {label: 'Surgery Date', value: 'surgery_date'},
        {label: 'Specific Date', value: 'specific_date'},
      ],
      daysConditions: [
        {label: 'In a Row', value: 'in_row'},
        {label: 'Till Now', value: 'till_now'},
      ],
      surgeryDaysConditions: [
        {label: 'Pre-Op', value: 'preop'},
        {label: 'Post-Op', value: 'postop'},
      ],
      surgeryDayTypes: [
        {label: 'Days', value: 'day'},
        {label: 'Weeks', value: 'week'},
        {label: 'Months', value: 'month'},
        {label: 'Years', value: 'year'},
      ],
      userTiers: [
        {label: 'Non-Connected', value: 'non_connected'},
        {label: 'Tier 1', value: 'tier_1'},
        {label: 'Tier 2', value: 'tier_2'},
        {label: 'Tier 3', value: 'tier_3'},
      ],
      request: {
        trophyCard: {...this.$store.getters['CardsAndTrophiesModule/getCardTrophy']}
      }
    }
  },
  watch: {
    cardTrophyDataToEdit(newVal) {
      this.request.trophyCard.title = newVal.title ?? ''
      this.request.trophyCard.user_tier = newVal.user_tier.split(',') ?? []
      this.request.trophyCard.rule_type = newVal.rule_type ?? 'food_log'
      this.request.trophyCard.date = newVal.date ?? ''
      this.request.trophyCard.days = newVal.days ?? null
      this.request.trophyCard.days_condition = newVal.days_condition ?? 'in_row'
      this.request.trophyCard.surgery_day_type = newVal.surgery_day_type ?? null
      this.request.trophyCard.surgery_days = newVal.surgery_days ?? null
      this.request.trophyCard.oldFrontImage = newVal.ad_front_image ?? null
      this.request.trophyCard.oldBackImage = newVal.ad_back_image ?? null
      this.request.trophyCard.link = newVal.link ?? ''
      this.request.trophyCard.notification_content = newVal.notification_content ?? ''
    },
    'request.trophyCard.rule_type'(newVal) {
      if(newVal != 'specific_date'){
        this.request.trophyCard.date =  ''
      }
      if(newVal == 'specific_date' && this.cardTrophyDataToEdit.date){
        this.request.trophyCard.date =  this.cardTrophyDataToEdit.date;
      }
    }
  },
  computed: {
    /**
     * Computed property to check front picture if exist already
     * @returns {false|string|boolean}
     */
    frontImageExist() {
      return this.request.trophyCard.oldFrontImage !== ''
        && this.request.trophyCard.oldFrontImage
        && this.request.trophyCard.ad_front_image === ''
    },

    /**
     * Computed property to check back picture if exist already
     * @returns {false|string|boolean}
     */
    backImageExist() {
      return this.request.trophyCard.oldBackImage !== ''
        && this.request.trophyCard.oldBackImage
        && this.request.trophyCard.ad_back_image === ''
    },
  },
  methods: {
    /**
     * Remove Back Image
     */
    removeBackImage() {
      this.request.trophyCard.oldBackImage = ''
    },

    /**
     * Update days conditions according to rule type
     */
    updateConditionsOnChangeRuleType() {
      if (this.request.trophyCard.rule_type == 'food_log' || this.request.trophyCard.rule_type == 'app_usage') {
        this.request.trophyCard.days = 1
        this.request.trophyCard.days_condition = 'in_row'
        this.request.trophyCard.surgery_days = ''
        this.request.trophyCard.surgery_day_type = ''
      } else {
        this.request.trophyCard.days = ''
        this.request.trophyCard.days_condition = 'preop'
        this.request.trophyCard.surgery_days = 1
        this.request.trophyCard.surgery_day_type = 'day'
      }
    },

    /**
     * Handles the image load
     */
    handleLoad() {
      this.loaders.image = false
    },

    /**
     * Save the front image file into trophy card data
     * @param {event} e - Event object
     */
    onFrontImageChange(e) {
      this.request.trophyCard.ad_front_image = e.target.files[0] || '';
    },

    /**
     * Save the back image file into trophy card data
     * @param {event} e - Event object
     */
    onBackImageChange(e) {
      this.request.trophyCard.ad_back_image = e.target.files[0] || '';
    },

    async onSubmit() {
      let vm = this
      const isTrophyCardFormValid = await this.$refs['formValidator'].validate();
      if (isTrophyCardFormValid) {
        vm.updateRuleTypeDayConditions()
        if (this.$route.name === 'AddTrophyCard') {
          vm.$emit('storeTrophyCard', vm.request.trophyCard);
        } else if (this.$route.name === 'EditTrophyCard') {
          vm.$emit('editTrophyCard', vm.request.trophyCard);
        }
      } else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please Enter Required Fields.'
        });
      }
    },

    /**
     * Update rule type days conditions
     */
    updateRuleTypeDayConditions() {
      if (this.request.trophyCard.rule_type == 'food_log' || this.request.trophyCard.rule_type == 'app_usage') {
        this.request.trophyCard.surgery_days = ''
        this.request.trophyCard.surgery_day_type = ''
      } else {
        this.request.trophyCard.days = ''
      }
    }
  },
}
</script>

<style>

.days-input > .el-input > .el-input__inner {
  height: 43px !important;
}

</style>
